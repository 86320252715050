import React from 'react'
import '../../App.css'
import ProjectSection from '../ProjectSection'

export default function Roots() {
    const projectProps = {
        url: "https://roots.com",
        title: "ROOTS",
        description: "Quickly build out your family tree."
      };
    
      return (
        <div className='roots'>
            <ProjectSection 
            url={projectProps.url}
            title={projectProps.title}
            description={projectProps.description}
            />
        </div>
      );
    }