import React from 'react';
import '../../App.css';
import ProjectSection from '../ProjectSection'


export default function Mapps() {
  const projectProps = {
    url: "https://mapps.com",
    title: "MAPPS",
    description: "Create and share custom mapps of your favorite locations."
  };

  return (
    <div className='mapps'>
        <ProjectSection 
        url={projectProps.url}
        title={projectProps.title}
        description={projectProps.description}
        />
    </div>
  );
}