import mixerLogo from './Mixer.svg';
import './App.css';
import NavBar from './components/NavBar'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import Mapps from './components/pages/Mapps'
import QuantumEd from './components/pages/QuantumEd'
import Shelves from './components/pages/Shelves'
import Mixer from './components/pages/Mixer'
import Roots from './components/pages/Roots'


/// splash/loading screen
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={mixerLogo} class="App-logo" />
//         <p>
//           Stocking the bar...
//         </p>
//       </header>
//     </div>
//   );
// }

function App() {
  return (
    <>
    <Router>
      <NavBar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/mapps' component={Mapps}/>
        <Route path='/mixer' component={Mixer}/>
        <Route path='/quantum-ed' component={QuantumEd}/>
        <Route path='/shelves' component={Shelves}/>
        <Route path='/roots' component={Roots}/>
      </Switch>
    </Router>
    </>
  );
}

export default App;
