import React from 'react'
import '../../App.css'
import ProjectSection from '../ProjectSection'

export default function Shelves() {
    const projectProps = {
        url: "https://shelves.com",
        title: "SHELVES",
        description: "Keep tabs on your home library."
      };
    
      return (
        <div className='shelves'>
            <ProjectSection 
            url={projectProps.url}
            title={projectProps.title}
            description={projectProps.description}
            />
        </div>
      );
    }