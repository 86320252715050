import React from 'react'
import '../../App.css'
import ProjectSection from '../ProjectSection'


export default function QuantumEd() {
    const projectProps = {
        url: "https://quantum-ed.com",
        title: "QUANTUM ED",
        description: "Interactive Lessons on Quantum Education."
      };
    
      return (
        <div className='quantum-ed'>
            <ProjectSection 
            url={projectProps.url}
            title={projectProps.title}
            description={projectProps.description}
            />
        </div>
      );
    }