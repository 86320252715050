import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import './Footer.css';


function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Reach out about anything you are curious about
                </p>
                <p className='footer-subscription-text'>
                   Shoot us an email here
                </p>
                <div className='input-areas'>
                    <form>
                        <input 
                            className='footer-input' 
                            type='email' 
                            name='email' 
                            placeholder='Your Email'
                        />
                        <Button
                            buttonStyle='btn--outline'>
                            Inquire
                        </Button>
                    </form>
                </div>
            </section>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    {/* <div class='footer-link-items'>
                        <h2>About Us</h2>
                        <Link to='/'>How we work</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Investors</Link>
                    </div>
                    <div class='footer-link-items'>
                        <h2>Contact Us</h2>
                        <Link to='/'>Contact</Link>
                        <Link to='/'>Support</Link>
                        <Link to='/'>Sponsor Dark House</Link>
                    </div>
                    </div>
                    <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>What's Next</h2>
                        <Link to='/'>Sleep</Link>
                        <Link to='/'>Personal accountability</Link>
                        <Link to='/'>Quantum AI ML</Link>
                    </div>
                    <div class='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='/'>Github ;)</Link>
                        <Link to='/'>Instagram</Link>
                        <Link to='/'>Youtube</Link>
                    </div> */}
                </div>
            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                        Dark House
                        <i class='fas fa-home' />
                        </Link>
                    </div>
                    <small class='website-rights'>Dark House LLC © 2024</small>
                    <div class='social-icons'>
                        <Link
                        class='social-icon-link youtube'
                        to='/'
                        target='_blank'
                        aria-label='Youtube'
                        >
                        <i class='fab fa-youtube' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
