import React from 'react'
import '../../App.css'
import ProjectSection from '../ProjectSection'


export default function Mixer() {
    const projectProps = {
        url: "https://mixxer.com",
        title: "MIXER",
        description: "This is a brief description of Mixer."
      };
    
      return (
        <div className='mixer'>
            <ProjectSection 
            url={projectProps.url}
            title={projectProps.title}
            description={projectProps.description}
            />
        </div>
      );
    }