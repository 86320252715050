import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import mixerLogo from '../Mixer.svg';
import { Button } from './Button.js';
import './NavBar.css'


function NavBar() {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }
    useEffect(() => {
        showButton([])
    })

    window.addEventListener('resize', showButton)
    
    return (
        <>
        <nav className="navbar">
            <div className="navbar-container">
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>
                {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/mapps' className='nav-links' onClick={closeMobileMenu}>
                            Mapps
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/mixer' className='nav-links' onClick={closeMobileMenu}>
                            Mixer
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/quantum-ed' className='nav-links' onClick={closeMobileMenu}>
                            Quantum Ed
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/shelves' className='nav-links' onClick={closeMobileMenu}>
                            Shelves
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/roots' className='nav-links' onClick={closeMobileMenu}>
                            Roots
                        </Link>
                    </li>
                </ul>
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    {/* <img src={mixerLogo} class="app-logo-small" /> */}
                    
                </Link>
            </div>
        </nav>

        </>
    )
}

export default NavBar
