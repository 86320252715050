import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Coming soon...</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/map3.jpg'
              text='Create and share mapps of your favorite locations'
              label='Mapps'
              path='/mapps'
            />
            <CardItem
              src='images/mcm-liquor-cabinet2.jpg'
              text="Thousands of drinks waiting to be enjoyed, yes, from what's in your liquor cabinet"
              label='Mixer'
              path='/mixer'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/home-library2.jpg'
              text='Keep tabs on your home library'
              label='Shelves'
              path='/shelves'
            />
            <CardItem
              src='images/quantum-computing-class.jpg'
              text='Interactive lessons on Quantum Computing'
              label='Quantum Ed'
              path='/quantum-ed'
            />
            <CardItem
              src='images/family-tree2.jpg'
              text='Build out your own free family tree'
              label='Roots'
              path='/roots'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
