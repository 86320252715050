import React from 'react'
import { Button } from './Button'
import './ProjectSection.css'
import '../App.css'


function ProjectSection(props) {

    const { url, title, description } = props

    function openProject() {
        window.open(url, '_blank'); // Opens the video URL in a new tab or window
    }

    return (
        <div className='project-container'>
            <h1>{title}</h1>
            <p>{description}</p>
            <div className='project-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' onClick={openProject}>
                    Open {title}
                    <i className="fas fa-external-link-alt"></i>
                </Button>
            </div>
        </div>
    )
}

export default ProjectSection
